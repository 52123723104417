//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import SearchTab from '../../components/mshop/HeaderSearchTab.vue'
  import MealList from '../../components/mshop/ShopList.vue'
  import Loading from '../../components/mshop/Loading.vue'
  import indexHead from '../../components/mshop/indexHead.vue'
  import { miceService } from '@/service/miceService'
  import PullTo from 'vue-pull-to';

  export default {
    name: 'index',
    components: {
      SearchTab,
      MealList,
      Loading,
      indexHead,
      PullTo
    },
    data: function () {
      return {
        loadFlag: true,
        loadingFlag: false,
        page: 0,
        searchData: {
          placeholder: '请输入餐厅名称'
        },
        selectData: {
          parameter: {},
          data: [
            {
              name: '商区',
              isSon: true,
              url: 'api/mshop/dictionary/QueryRegion',
              data: []
            }, {
              name: '医院'
            }, {
              name: '排序',
              data: [
                {val: '默认排序', txt: '默认排序', orderby: 'StarVal DESC'},
                {val: '服务最佳', txt: '服务最佳', orderby: 'ScoreServiceVal DESC'},
                {val: '口味最佳', txt: '口味最佳', orderby: 'ScoreTasteVal DESC'},
                {val: '环境最佳', txt: '环境最佳', orderby: 'ScoreEnvirVal DESC'}
              ]
            }
          ]
        },
        list: {
          listQueryParameter: {pageindex: 1},
          data: []
        },
        hospitalfixed: false,//是否直接显示医院
        hospital: '',//
        isBack: false
      }
    },
    computed: {
      showXianXia: {
        get: () => {
          const tenantcode = sessionStorage.getItem('tenantcode') || 'roche';
          const changeshop = sessionStorage.getItem('changeshop');
          if(tenantcode == 'novartis' || tenantcode == 'merck' || changeshop) return false;
          return true
        }
      },      
      fromrouter: function () {
        return this.common.toLowerCase(this.$route.query);
      },
    },
    watch: {
      fromrouter: function (val, oldValue) {
        if (val !== oldValue) {
          // alert(1)
          this.init()
        }
      },
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        var _this = this
        this._data.query = this.common.toLowerCase(this.$route.query)
        // console.log(this._data.query)
        this._data.query.cityid = this._data.query.cityid || 1

        var nowData = {
          cityId: this._data.query.cityid,
          NL: sessionStorage.getItem('nl'),
          EL: sessionStorage.getItem('el'),
          gpsCity: sessionStorage.getItem('gpscity'),
          proposalType: this._data.query.proposaltype,
          hospitalId: this._data.query.hospitalid,
          tenantId: this._data.query.tenantid
        }
        if (this._data.query.hospitalfixed) {
          nowData.RowId = this._data.query.hospitalid
        }

        if (this._data.query.frompage) {
          _this._data.isBack = true
        }
        let rejecttabs = this._data.query.rejecttabs
        let indexArray = rejecttabs ? rejecttabs.split(',') : []
        this.hospitalfixed = this._data.query.hospitalfixed ? this._data.query.hospitalfixed : false
        if(this._data.query.proposaltype=='科室会'||this._data.query.proposaltype=='院内会') {
          // alert(1)
          this.hospitalfixed = 2
        }
        let isOne = indexArray.length > 0 ? false : true
        if (isOne) {
          miceService.queryRegion(nowData).then(res => {
            res.data.unshift({
              type: "all1",
              txt: "全部商区",
              val: "全部商区",
              name: "全部商区"
            });
            _this._data.selectData.data[0].data = res.data
          })
        } else {
          for (var i = 0, L = indexArray.length; i < L; i++) {
            _this._data.selectData.data.splice(Number(indexArray[i]) - i, 1)
          }
        }
        miceService.hospitalQuery(nowData).then(res => {
          if (!_this._data.query.hospitalfixed) {
            res.data.unshift({
              name: "全部医院",
              type: "all2",
              cityId: _this._data.query.cityid,
              rowId: ''
            });
          }
          _this._data.hospital = res.data
          // _this._data.selectData.data[1].data = res.data
          for (var i = 0, L = _this._data.selectData.data.length; i < L; i++) {
            if (_this._data.selectData.data[i].name == "医院") {
              _this._data.selectData.data[i].data = res.data || []
            }
          }
        })
        this.listenTolistLoadMore()
      },
      listenToSearchExternal: function (data) {
        this.$router.push({
          name: 'Search',
          query: {
            keyword: data,
            cityId: this._data.query.cityid,
            proposalType: this._data.query.proposaltype,
            tenantId: this._data.query.tenantid,
            hospitalId: this._data.query.hospitalid,
            hospitalfixed: this._data.query.hospitalfixed
          }
        })
      },
      listenToTabClick: function (data) {
        this._data.list.listQueryParameter.pageindex = 1
        if (data.type != "3") {
          this._data.list.data = []
          this.loadFlag = true;
        }
        if (data.pingRegionId) {
          this._data.list.listQueryParameter.locationTxt = data.txt
          this._data.list.listQueryParameter.areaId = data.pingRegionId
        } else if (data.rowId) {
          this._data.list.listQueryParameter.hospitalId = data.rowId
          this._data.query.hospitalid = data.rowId
          this._data.list.listQueryParameter.locationTxt = data.name
        } else if (data.orderby) {
          this._data.list.listQueryParameter.orderBy = data.orderby
          this._data.list.listQueryParameter.orderTxt = data.val
        }
        if (data.type == "all1") {
          this._data.list.listQueryParameter.locationTxt = ""
          this._data.list.listQueryParameter.areaId = ""
        } else if (data.type == "all2") {
          this._data.list.listQueryParameter.locationTxt = ""
          this._data.list.listQueryParameter.hospitalId = ""
          this._data.query.hospitalid = ''
        }

        if (data.type != "3") {
          this.listenTolistLoadMore();
        }
      },
      listenTolistLoadMore: function () {
        var _this = this
        this._data.list.listQueryParameter.cityid = this._data.query.cityid || 1
        this._data.list.listQueryParameter.NL = sessionStorage.getItem('nl')
        this._data.list.listQueryParameter.EL = sessionStorage.getItem('el')
        this._data.list.listQueryParameter.gpsCity = sessionStorage.getItem('gpscity')
        this._data.list.listQueryParameter.proposaltype = this._data.query.proposaltype
        this._data.list.listQueryParameter.hospitalId = this._data.query.hospitalid
        this._data.list.listQueryParameter.tenantId = this._data.query.tenantid
        if (!this.loadFlag) {
          return;
        }
        let obj = this._data.list.listQueryParameter
        for(var i in obj) {
          if(!obj[i]) {
            delete obj[i]
          }
        }
        miceService.paging(obj).then(res => {
          _this.common.arrPushArr(_this._data.list.data, res.data)
          _this.page = _this._data.list.listQueryParameter.pageindex
          _this._data.list.listQueryParameter.pageindex++
          if (res.data.length < 10) {
            _this.loadFlag = false;
          } else {
            _this.loadFlag = true;
          }
          if (res.data.length == 0) {
            _this.loadingFlag = true;
          } else {
            _this.loadingFlag = false;
          }
        }).catch(err => {
          _this.$router.push({name: 'Error'})
        })
      },
      listenToListClick: function (data) {
        sessionStorage.setItem('selectedShop', JSON.stringify(data))
        this.$router.push({
          name: 'Detail', query: {
            rowId: data.rowId,
            proposalType: this._data.query.proposaltype,
            tenantId: sessionStorage.getItem('tenantid')
          }
        })
      },
      selectXianXia() {
       this.$router.push({ name: 'selectAddress', params:{isXianXia: true} })
      }
    },
  }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import Vue from 'vue'
  import { miceService } from '@/service/miceService'
  import config  from "@/service/config"
  export default {
    name: 'searchTab',
    props: ['value'],
    methods: {
      filterKey: function (key, items) {
        return items.filter(function (item) {
//        console.log(item)
          if (!key) {
            return item
          } else {
            return item.name.indexOf(key) !=-1
          }
        })
      },
      switchToggle: function (item) {
        if (item.class) {
          Vue.set(item, 'class', '')
          this._data.SelectData.config.status = false
        } else {
          this.value.forEach(function (item2) {
            Vue.set(item2, 'class', '')
          })
          item.class = 'active'
          this._data.SelectData.config.status = true
        }
      },
      selectItem: function (items, item) {
        items.data.forEach(function (item2) {
          Vue.set(item2, 'class', '')
          item.class = 'active'
        })
        if (items.isSon) {
          if (item.type == "all1") {
            items.name = item.txt || item.name
            this.switchToggle(items)
          } else {
            miceService.commonGet(config.gateway+'/'+items.url, {'parentId': item.regionId}).then(res => {
              Vue.set(item, 'data', res.data)
              item.data.forEach(function (item2) {
                if (item2.txt === item.name) {
                  item.class = 'active'
                }
              })
            })
          }
        } else {
          items.name = item.txt || item.name
          this.switchToggle(items)
        }
        this.$emit('fromTabClick', item)
      },
      selectItem2: function (itemss, items, item) {
        itemss.name = item.txt || item.name
        items.data.forEach(function (item2) {
          Vue.set(item2, 'class', '')
        })
        item.class = 'active'
        this.switchToggle(itemss)
        this.$emit('fromTabClick', item)
      },
      closeShadow: function () {
        this._data.SelectData.config.status = false
        this.value.forEach(function (item) {
          Vue.set(item, 'class', '')
        })
      },
    },
    data: function () {
      return {
        SelectData: {
          config: {
            status: false
          }
        },
        key: null
      }
    }
  }

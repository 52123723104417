//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["isBack"],
  name: "indexHead",
  watch: {},
  created() {
    this.address = this.iStorage.get('address');
  },
  methods: {
    doClose: function() {
      if (this.isBack) {
        this.$root.goStatus = -1;
        this.$router.go(-1);
        return;
      }
      this.iJsBridge.close();
    },
    goSearch: function() {
      this.$root.goStatus = 0;
      this.$emit("fromSearchExternal", "");
    },
    goMap: function() {
      this.$router.push({ path: '/selectAddress/' })
    }
  },
  data: function() {
    return {
      searchData: {
        placeholder: '请输入商户名'
      }, 
      address: ''     
    };
  }
};
